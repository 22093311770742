import React from 'react';
import { css } from '@emotion/react';
import config from '../../config';
import Preloader from '../Preloader';
import LogoBrandIcon from '../common/Icons/LogoBrandIcon';
import logoLogisticForce from '../../../theme/img/logisticForce/logo_header_LF.png';
import logoTerborg from '../../../theme/img/terborg/logo_terborg.png';
import logoDeBuro from '../../../theme/img/deburo/deburo.png';

const LoadingComponent = () => {
  const wrapCss = css({
    background: '#fafafa',
    position: 'relative',
    minHeight: '100vh',
  });
  const logoCss = css({
    position: 'absolute',
    marginTop: '5%',
    left: '50%',
    width: '184px',
    height: '48px',
    transform: 'translateX(-50%)',
  });
  const logisticForceCss = css({
    display: 'flex',
    justifyContent: 'center',
  });
  const terborgImgCss = css({
    marginTop: '2%',
    height: '130px',
  });

  return (
    <div css={wrapCss}>
      {config.isLogisticForce && (
        <div css={logisticForceCss}>
          <img
            src={logoLogisticForce}
            alt="logisticForce"
          />
        </div>
      )}
      {config.isTerborg && (
        <div css={logisticForceCss}>
          <img
            src={logoTerborg}
            css={terborgImgCss}
            alt="terborg"
          />
        </div>
      )}
      {config.isDeBuro && (
        <div css={logisticForceCss}>
          <img
            src={logoDeBuro}
            css={terborgImgCss}
            alt="deburo"
          />
        </div>
      )}
      {!config.isCustomApp && (
        <div css={logoCss}>
          <LogoBrandIcon />
        </div>
      )}
      <Preloader isDefault={config.isCustomApp} />
    </div>
  );
};

export default LoadingComponent;
