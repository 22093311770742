import React from 'react';
import config from '../../config';
import Preloader from '../Preloader';

const ShiftCreateLoading = () => (
  <div className="pageMainWrapper">
    <div className="shiftDetail">
      <div className="shiftDetail__header">
        <div
          css={{
            height: '34px',
            background: '#dcdcdc',
            borderRadius: '3px',
            width: '50%',
          }}
        />
      </div>
      <div className="shiftDetail__content">
        <div className="shiftDetail__content-left">
          {[...Array(5).keys()].map((index) => (
            <div
              key={index}
              css={{
                margin: '25px',
              }}
            >
              <div
                css={{
                  height: '14px',
                  background: '#cfcfcf',
                  width: '50%',
                }}
              />
              <div
                css={{
                  height: '34px',
                  background: '#dcdcdc',
                  borderRadius: '3px',
                  marginTop: '10px',
                }}
              />
            </div>
          ))}

        </div>
        <div className="shiftDetail__content-right">
          {[...Array(7).keys()].map((index) => (
            <div
              key={index}
              css={{
                margin: '25px',
              }}
            >
              <div
                css={{
                  height: '14px',
                  background: '#cfcfcf',
                  width: '50%',
                }}
              />
              <div
                css={{
                  height: '34px',
                  background: '#dcdcdc',
                  borderRadius: '3px',
                  marginTop: '10px',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    <Preloader isDefault={config.isCustomApp} />
  </div>
);

export default ShiftCreateLoading;
