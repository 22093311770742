import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const OrganizationsImportPage = loadable(() => import(
  /* webpackChunkName: "organizations-import-page-chunk" */
  './OrganizationsImportPage'
), {
  fallback: (<Preloader isDefault={config.isCustomApp} />),
});

export default OrganizationsImportPage;
