import React from 'react';
import { ProfileContext } from './ProfileContext';
import { FeatureContext } from './FeatureContext';
import { TranslationContext } from './TranslationContext';

export const withContexts = (Component) => (props) => (
  <TranslationContext.Consumer>
    {(translationContext) => (
      <ProfileContext.Consumer>
        {(profileContext) => (
          <FeatureContext.Consumer>
            {(featureContext) => (
              <Component
                {...props}
                translationContext={translationContext}
                profileContext={profileContext}
                featureContext={featureContext}
              />
            )}
          </FeatureContext.Consumer>
        )}
      </ProfileContext.Consumer>
    )}
  </TranslationContext.Consumer>
);