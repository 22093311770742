import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const MyProfile = loadable(
  () => import(
    /* webpackChunkName: "profile-page-chunk" */
    './MyProfile'
  ),
  {
    fallback: (
      <Preloader isDefault={config.isCustomApp} />
    ),
  }
);

export default MyProfile;
