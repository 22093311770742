import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { setNetworkStatus } from '../../redux/modules/info';
import config from '../../config';
import { getIsMaintenance } from '../../services/selectors';
import {
  getTranslates,
  getCurrentLang,
} from '../../services/translateSelector';
import Preloader from '../../components/Preloader';
import { Maintenance } from '../../components/Maintenance/Maintenance';
import darkFavicon from '../../../theme/img/clevergig_logo_cmyk_element.png';
import whiteFavicon from '../../../theme/img/clevergig_logo_wit_element.png';
import faviconLF from '../../../theme/img/logisticForce/logo_header_LF.png';
import logoTerborg from '../../../theme/img/terborg/logo_terborg.png';
import logoDeBuro from '../../../theme/img/deburo/deburo_icon.png';

const mapStateToProps = (state) => ({
  localization: getTranslates(state),
  currentLang: getCurrentLang(state),
  isMaintenance: getIsMaintenance(state),
});

const dispatchToProps = (dispatch) => ({
  setNetworkStatus: (status) => dispatch(setNetworkStatus(status)),
});

export const App = (props) => {
  const {
    localization: tr,
    currentLang,
    children,
    isMaintenance,
  } = props;

  const [colorScheme, setColorScheme] = useState('dark');

  /**
   * Handle connection
   * @param {object} event SyntheticEvent
   */
  const handleConnection = (event) => {
    props.setNetworkStatus(event.type);
  };

  useEffect(() => {
    window.addEventListener('online', handleConnection);
    window.addEventListener('offline', handleConnection);

    return () => {
      window.removeEventListener('online', handleConnection);
      window.removeEventListener('offline', handleConnection);
    };
  }, []);

  const updateTheme = () => {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setColorScheme(() => isDarkMode ? 'dark' : 'light');
  };

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);
  }, []);

  if (isEmpty(tr)) {
    return (<Preloader isDefault={config.isCustomApp} />);
  }

  if (isMaintenance) {
    return (<Maintenance tr={tr} />);
  }

  const titleTemplates = {
    isLogisticForce: 'LogisticForce: %s',
    isTerborg: 'Terborg Zorg: %s',
    isDeBuro: 'DeBuro: %s',
    default: 'clevergig: %s',
  };
  const prepareTitle = (config.isLogisticForce && titleTemplates.isLogisticForce)
    || (config.isTerborg && titleTemplates.isTerborg)
    || (config.isDeBuro && titleTemplates.isDeBuro)
    || titleTemplates.default;

  // Determine favicon based on config
  let faviconPath;
  switch (true) {
    case config.isLogisticForce:
      faviconPath = faviconLF;
      break;
    case config.isTerborg:
      faviconPath = logoTerborg;
      break;
    case config.isDeBuro:
      faviconPath = logoDeBuro;
      break;
    default:
      faviconPath = colorScheme === 'dark' ? whiteFavicon : darkFavicon;
      break;
  }

  return (
    <div>
      <Helmet
        titleTemplate={prepareTitle}
        htmlAttributes={{ lang: currentLang }}
        title={tr.global.metaTitle}
        meta={config.app.head.meta}
      >
        {/* Add favicon links */}
        <link rel="icon" href={faviconPath} type="image/x-icon" />
        <link rel="apple-touch-icon" href={faviconPath} type="image/x-icon" />
      </Helmet>
      {children}
      <ToastContainer />
    </div>
  );
};

App.displayName = 'App';
App.propTypes = {
  currentLang: PropTypes.string.isRequired,
  localization: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  isMaintenance: PropTypes.bool.isRequired,
  setNetworkStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, dispatchToProps)(App);
