import React from 'react';
import loadable from '@loadable/component';
import config from '../../../config';
import Preloader from '../../../components/Preloader';

const ProtectedRoutes = loadable(
  () => import(
    /* webpackChunkName: "protected-page-chunk", webpackPrefetch: true */
    './ProtectedRoutes'
  ),
  {
    fallback: (
      <Preloader isDefault={config.isCustomApp} />
    ),
  }
);

export default ProtectedRoutes;