import isFunction from 'lodash/isFunction';
import { checkItemInArray } from '../../utils/utils';

/**
 * Constants
 */
const LOAD = 'redux-ducks/roles/LOAD';
const LOAD_SUCCESS = 'redux-ducks/roles/LOAD_SUCCESS';
const LOAD_FAIL = 'redux-ducks/roles/LOAD_FAIL';

const SAVE = 'redux-ducks/roles/SAVE';
const SAVE_SUCCESS = 'redux-ducks/roles/SAVE_SUCCESS';
const SAVE_FAIL = 'redux-ducks/roles/SAVE_FAIL';

const UPDATE = 'redux-ducks/roles/UPDATE';
const UPDATE_SUCCESS = 'redux-ducks/roles/UPDATE_SUCCESS';
const UPDATE_FAIL = 'redux-ducks/roles/UPDATE_FAIL';

const DELETE = 'redux-ducks/roles/DELETE';
const DELETE_SUCCESS = 'redux-ducks/roles/DELETE_SUCCESS';
const DELETE_FAIL = 'redux-ducks/roles/DELETE_FAIL';

const SET_DEFAULT = 'redux-ducks/roles/SET_DEFAULT';

const initialState = {
  loading: false,
  entity: {
    allIds: [],
    byId: {},
  },
};

/**
 * Load roles
 */
export const load = () => ({
  types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
  promise: (client) => client.get('roles'),
});

/**
 * Save role
 * @param {object} data
 */
export const saveRole = (data) => ({
  types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
  promise: (client) => client.post('roles', { data }),
});

/**
 * Update role
 * @param {*}
 */
export const updateRole = (data) => ({
  types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
  promise: (client) => client.patch(`roles/${data.id}`, { data }),
  data,
});

/**
 * Delete role
 * @param {number} id
 */
export const deleteRole = (id) => ({
  types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
  promise: (client) => client.del(`roles/${id}`),
  id,
});

/**
 * Set default state
 */
export const setDefault = () => ({ type: SET_DEFAULT });

//
// Mutations
// *************************************

const onLoadRoles = (value) => (state) => ({
  ...state,
  loading: value,
});

const loadRolesSuccess = (action) => (state) => {
  const { roles } = action.result;
  let updId = [];
  const normalize = {};

  roles.forEach((item) => {
    if (!checkItemInArray(updId, item.id)) {
      updId = [...updId, item.id];
    }

    normalize[item.id] = item;
  });

  return ({
    ...state,
    loading: false,
    entity: {
      allIds: updId,
      byId: normalize
    }
  });
};

const onLoadRolesFail = () => (state) => ({
  ...state,
  loading: false,
  entity: {
    allIds: [],
    byId: {},
  }
});

const saveSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const { role } = action.result;

  return ({
    ...state,
    entity: {
      byId: {
        ...byId,
        [role.id]: role,
      },
      allIds: [
        ...allIds,
        role.id
      ],
    },
  });
};

const updateSuccess = (action) => (state) => {
  const { role } = action.result;
  const { allIds } = state.entity;

  if (!checkItemInArray(allIds, role.id)) {
    return ({
      ...state,
    });
  }

  return ({
    ...state,
    entity: {
      ...state.entity,
      byId: {
        ...state.entity.byId,
        [role.id]: role,
      }
    }
  });
};

const deleteSuccess = (action) => (state) => {
  const { allIds, byId } = state.entity;
  const filteredAllIds = allIds.filter((id) => id !== action.id);

  const updatedById = { ...byId };
  delete updatedById[action.id];

  return ({
    ...state,
    entity: {
      allIds: filteredAllIds,
      byId: updatedById,
    },
  });
};

const setDefaultSuccess = () => initialState;

const actionsLookup = {
  [LOAD]: (state) => onLoadRoles(true)(state),
  [LOAD_SUCCESS]: (state, action) => loadRolesSuccess(action)(state),
  [LOAD_FAIL]: (state) => onLoadRolesFail()(state),
  [SAVE_SUCCESS]: (state, action) => saveSuccess(action)(state),
  [UPDATE_SUCCESS]: (state, action) => updateSuccess(action)(state),
  [DELETE_SUCCESS]: (state, action) => deleteSuccess(action)(state),
  [SET_DEFAULT]: () => setDefaultSuccess(),
};

export default function reducer(state = initialState, action = {}) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
