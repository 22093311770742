import React from 'react';
import config from '../../config';
import Preloader from '../../components/Preloader';
import ProjectsTableLoading from './ProjectsTableLoading';

const ProjectsLoading = () => (
  <div className="pageMainWrapper">
    <div className="pageMainWrapper__header">
      <div className="pageMainWrapper__header-left">
        <div
          css={{
            height: '34px',
            background: '#dcdcdc',
            borderRadius: '3px',
            width: '50%',
          }}
        />
      </div>
    </div>
    <ProjectsTableLoading />
    <Preloader isDefault={config.isCustomApp} />
  </div>
);

export default ProjectsLoading;
