import React from 'react';
import config from '../../config';
import LogoBrandIcon from '../common/Icons/LogoBrandIcon';
import logoLogisticForce from '../../../theme/img/logisticForce/logo_header_LF.png';
import logoTerborg from '../../../theme/img/terborg/logo_terborg.png';
import logoDeBuro from '../../../theme/img/deburo/deburo.png';

const LogoHeader = () => {
  if (config.isLogisticForce) {
    return (
      <div className="guestPage__header">
        <img
          src={logoLogisticForce}
          alt="logisticForce"
        />
      </div>
    );
  }

  if (config.isTerborg) {
    return (
      <div className="guestPage__header">
        <img
          className="guestPage__header-logoHeight"
          src={logoTerborg}
          alt="terborg"
        />
      </div>
    );
  }

  if (config.isDeBuro) {
    return (
      <div className="guestPage__header">
        <img
          className="guestPage__header-logoHeight"
          src={logoDeBuro}
          alt="deburo"
        />
      </div>
    );
  }

  return (
    <div className="guestPage__header">
      <a
        className="guestPage__header-logo"
        href={config.marketPage}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LogoBrandIcon />
      </a>
    </div>
  );
};

export default LogoHeader;
